<template>
  <div class="notification-bar" :class="getClass">
    {{ notification.message }}
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeout: null,
    }
  },
  mounted() {
    this.timeout = setTimeout(() => this.remove(this.notification), 7000)
  },
  unmounted() {
    clearTimeout(this.timeout)
  },
  computed: {
    getClass() {
      return 'type-' + this.notification.type;
    }
  },
  methods: mapActions('notification', ['remove']),
}
</script>

<style>
.notification-bar {
  background-color: rgb(139, 199, 81, 0.9);
  color: #ffffff;
  width: 100%;
  padding: 12px 15px;
  text-align: center;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  font-size: 20px;
  line-height: 28px;
}

.notification-bar.type-warning {
  background-color: rgb(220, 12, 21, 0.9);

}
</style>
